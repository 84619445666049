<template>
  <div  class="mt-5">

  <v-data-table
    dense
    :headers="headers"
    :items="users"
    :items-per-page="500"
    class="table-users elevation-1"
  ></v-data-table>

  </div>
</template>

<script>
  import { fetchUsers } from '@/api/admin'

  export default {
    data() {
      return {
        headers: [
          { value: 'full_name', text: 'Name' },
          { value: 'email', text: 'Email' },
          { value: 'telephone', text: 'Telephone' },
          { value: 'datestamp', text: 'Date registered' }
        ],
        users: []
      }
    },
    methods: {
      async fetchUsers () {
        const response = await fetchUsers()
        console.log("mounted -> response", response)
        this.users = response
      },
    },
    mounted () {
      this.fetchUsers()
    },
  }
</script>

<style lang="scss" scoped>
.table-users {
  height: calc(100vh - 101px);
  overflow: auto;
}
</style>